.textarea-component {
  overflow-y: auto; /* Enable vertical scrolling */
  resize: vertical; /* Allow vertical resizing */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #a0a0a0 #f0f0f0; /* Thumb and track color for Firefox */
}

.textarea-component::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.textarea-component::-webkit-scrollbar-thumb {
  background: #a0a0a0; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for thumb */
}

.textarea-component::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color on hover */
}

.textarea-component::-webkit-scrollbar-track {
  background: #f0f0f0; /* Color of the scrollbar track */
}
